//excluding rules from being affected by rtlcss
// scss-lint:disable Comment
/*rtl:begin:ignore*/
// scss-lint:enable Comment

:lang(ar) {
  body {
    font-family: "Noto Naskh Arabic", "Noto Sans Arabic", sans-serif;
    font-size: 17px;
  }

  // Safari tends to want to position placeholder a little too high
  ::-webkit-input-placeholder {
    line-height: 1.8em;
  }

  // Sometimes Arabic fonts in inline elements will introduce strange white space unless given this class
  .arabic-whitespace-fix {
    white-space: nowrap;
  }

  // bootstrap/_forms.scss
  .form-group {
    .email {
      direction: ltr;
      text-align: right;
    }
  }

  //resources/_steps.scss
  .numbered-list__contents {
    line-height: initial;
    top: -0.3em;
  }
}

// scss-lint:disable Comment
/*rtl:end:ignore*/
// scss-lint:enable Comment
