// Bootstrap
@import "bootstrap-config";
@import "bootstrap-import";
@import "bootstrap-overrides";

// Slider/Carousel
/*rtl:begin:ignore*/
@import "swiper/css";
@import "swiper/css/effect-fade";
@import "swiper/css/grid";

.swiper-slide {
  height: auto; // normalize slide height
}
.swiper--per-view-auto > .swiper-slide {
  width: auto; // needed for slidesPerView: "auto"
}

/*rtl:end:ignore*/
